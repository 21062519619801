import { useEffect } from 'react';

import loadable from '@loadable/component';
import cx from 'classnames';

import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
  LOAD_STATUS,
  PRODUCT_GROUP,
} from '../../constants';
import logMiniEventHelper from '../../mini-event/logMiniEventHelper';

import LogoImage from './LogoImage/LogoImage';

import type {
  ProviderInfo,
  ProviderLogosProps,
} from 'common-types/types/ProviderLogos';
import type { Vertical } from 'common-types/types/Vertical';

import STYLES from './ProviderLogos.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const handleClick = (vertical: Vertical, props: ProviderInfo) => {
  if (vertical === PRODUCT_GROUP.CAR_HIRE) {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_CLICKED,
      component_name: COMPONENT_NAME.PROVIDER_LOGOS,
      component_action: COMPONENT_ACTION.PROVIDER_LOGOS.SUPPLIER_LOGO_CLICKED,
      supplier: {
        id: Number(props.id),
        name: props.providerName,
      },
    });
  }
};

const ProviderLogos = ({
  enableLogoShadow,
  headerText,
  openLinkInNewTab,
  providerInfo,
  vertical,
}: ProviderLogosProps) => {
  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.PROVIDER_LOGOS,
      load_status:
        !headerText || !providerInfo?.length
          ? LOAD_STATUS.INVALID
          : LOAD_STATUS.LOADED,
    });
  }, [headerText, providerInfo]);

  if (!providerInfo?.length) {
    return null;
  }

  return (
    <div className={STYLES.ProviderLogos}>
      {headerText && (
        <div
          className={STYLES.ProviderLogos__header}
          data-testid="provider-logos-header"
        >
          <BpkSectionHeader title={headerText} />
        </div>
      )}

      <div className={STYLES.logos}>
        {providerInfo.map((provider) => {
          const image = <LogoImage {...provider} />;

          return (
            <div
              key={provider.providerName}
              className={cx(STYLES.ProviderLogos__providerLogo, {
                [STYLES.ProviderLogos__providerLogoShadow]: enableLogoShadow,
              })}
            >
              {provider.link ? (
                <a
                  className={STYLES.ProviderLogos__providerLogoLink}
                  aria-label={provider.providerName}
                  href={provider.link}
                  target={openLinkInNewTab ? '_blank' : ''}
                  onClick={() => handleClick(vertical, provider)}
                  rel="noreferrer"
                >
                  {image}
                </a>
              ) : (
                image
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default (props: ProviderLogosProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.PROVIDER_LOGOS,
      })
    }
  >
    <ProviderLogos {...props} />
  </IntersectionObserverWrapper>
);
