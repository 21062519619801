import { useState } from 'react';

import BpkImage, {
  withLazyLoading,
} from '@skyscanner/backpack-web/bpk-component-image';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text';

import type { ProviderInfo } from 'common-types/types/ProviderLogos';

import STYLES from '../ProviderLogos.module.scss';

const documentIfExists = typeof window !== 'undefined' ? document : null;
const LazyLoadedImage = withLazyLoading(BpkImage, documentIfExists);

const LogoImage = ({ providerName, src }: ProviderInfo) => {
  const [fallbackToText, setFallbackToText] = useState(false);

  const image = fallbackToText ? (
    <BpkText
      className={STYLES.ProviderLogos__providerLogoText}
      data-testid="partner-logo-span"
      textStyle={TEXT_STYLES.label2}
    >
      {providerName}
    </BpkText>
  ) : (
    <div className={STYLES.ProviderLogos__providerLogoImage}>
      <LazyLoadedImage
        altText={providerName}
        src={src}
        aspectRatio={120 / 60}
        onError={() => setFallbackToText(true)}
      />
    </div>
  );
  return image;
};

export default LogoImage;
